<template>
  <div class="relative min-h-screen md:flex">
    <SideNavBar />

    <div class="flex-1 p-10 text-2xl">
      <!-- header -->
      <div class="flex justify-between flex-wrap md:flex-nowwrap items-center">
        <div class="flex">
          <ChevronLeftIcon
            @click="navigateToManageDoctor"
            class="h-7 w-7 pt-2 cursor-pointer"
          />
          <h2 class="font-semibold">New Doctor Info</h2>
        </div>
      </div>
      <!-- end of header -->

      <!-- content -->
      <div v-if="isLoading" class="loader"></div>

      <div v-if="!isLoading" class="mt-5">
        <form @submit.prevent="addDoctor" class="card pt-2 form-control">
          <label class="label"
            ><span class="label-text text-black"
              >Name <span class="text-red-500">*</span></span
            ></label
          >
          <input
            v-model="doctorName"
            type="text"
            class="input input-md input-bordered w-full"
            required
          />

          <label class="label"
            ><span class="label-text text-black"
              >Position <span class="text-red-500">*</span></span
            ></label
          >
          <input
            v-model="doctorPosition"
            type="text"
            class="input input-md input-bordered w-full"
            required
          />

          <label class="label"
            ><span class="label-text text-black">Credentials</span></label
          >
          <ckeditor
            v-model="doctorCredentials"
            class="text-xs"
            :editor="editor"
          ></ckeditor>

          <label class="label"
            ><span class="label-text text-black"
              >Clinical Interests</span
            ></label
          >
          <ckeditor
            v-model="doctorClinicalInterest"
            class="text-xs"
            :editor="editor"
          ></ckeditor>

          <label class="label"
            ><span class="label-text text-black">About</span></label
          >
          <ckeditor
            v-model="doctorAbout"
            class="text-xs"
            :editor="editor"
          ></ckeditor>

          <label class="label"
            ><span class="label-text text-black">Doctor Image</span></label
          >
          <DragDropImage
            @changed="handleDoctorImage"
            :max="1"
            class="text-black"
            clearAll="Clear All"
            maxError="Maximum one file only"
          />

          <br />
          <button class="btn bg-green-500 border-none mt-3">Submit</button>
        </form>
      </div>
      <!-- end of content -->
    </div>
  </div>
</template>

<script>
import SideNavBar from "../../components/SideNavBar.vue";
import DragDropImage from "../../components/DragDropImage.vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

import { ChevronLeftIcon } from "@heroicons/vue/outline";

export default {
  name: "AddDoctor",
  components: { SideNavBar, DragDropImage, ChevronLeftIcon },
  data() {
    return {
      isLoading: false,
      editor: ClassicEditor,
      doctorName: "",
      doctorPosition: "",
      doctorCredentials: "",
      doctorClinicalInterest: "",
      doctorAbout: "",
      doctorImageSrc: [],
    };
  },
  methods: {
    addDoctor() {
      this.isLoading = true;
      let formData = new FormData();
      formData.append("doctorName", this.doctorName);
      formData.append("doctorPosition", this.doctorPosition);
      formData.append("doctorCredentials", this.doctorCredentials);
      formData.append("doctorClinicalInterest", this.doctorClinicalInterest);
      formData.append("doctorAbout", this.doctorAbout);
      formData.append("doctorImageSrc", this.doctorImageSrc);

      this.axios
        .post("/doctor/addDoctor", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          this.$swal.fire({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            icon: "success",
            title: response.data["message"],
          });
          this.isLoading = false;
          this.navigateToManageDoctor();
        })
        .catch((error) => {
          if (error.response.status == 401) {
            this.$router.push({
              name: "Login",
              query: {
                error:
                  "Your account has been locked by admin. If you think this is a mistake, please contact your admin. Thank you.",
              },
            });
            this.$store.dispatch("createUserSession", null); // remove user state to prevent user from logging in
          }
        });
    },
    handleDoctorImage(files) {
      this.doctorImageSrc = files[0];
    },
    navigateToManageDoctor() {
      this.$router.push({ name: "ManageDoctor" });
    },
  },
};
</script>

<style>
@import "../../assets/CustomCKEditorStyle.css";
</style>